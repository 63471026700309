import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-paterson-new-jersey.png'
import image0 from "../../images/cities/scale-model-of-paterson-great-falls-national-historical-park-in-paterson-new-jersey.png"
import image1 from "../../images/cities/scale-model-of-paterson-museum-in-paterson-new-jersey.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Paterson'
            state='New Jersey'
            image={image}
            lat='40.9148'
            lon='-74.1628'
            attractions={ [{"name": "Paterson Great Falls National Historical Park", "vicinity": "72 McBride Ave, Paterson", "types": ["park", "point_of_interest", "establishment"], "lat": 40.9172641, "lng": -74.1811892}, {"name": "Paterson Museum", "vicinity": "2 Market St, Paterson", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.9134463, "lng": -74.17888640000001}] }
            attractionImages={ {"Paterson Great Falls National Historical Park":image0,"Paterson Museum":image1,} }
       />);
  }
}